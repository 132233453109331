import React, { useState, useEffect, useContext } from "react";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import ZodiacDD from "../../../components/ZodiacDD";
import banner from "../../../staticAssets/Horoscope Bg.png";
import logo from "../../../staticAssets/Gemini.png";
import axios from "axios";
import { navigate } from "gatsby";
import { AuthContext } from "../../../context/Auth";
import { Helmet } from "react-helmet-async";
import { AnalyticsContext } from "../../../context/Analytics";

function Index({location}) {
  const [blogs, setblogs] = useState([]);
const { gtmPageViewEvent } = useContext(AnalyticsContext) || {
    gtmPageViewEvent: () => {},
  };
  const { user } = useContext(AuthContext) || {
    user: {},
  };
  useEffect(() => {
    const fn = async () => {
      let res = await axios.get(
        "https://www.bodhi.app/blog/wp-json/wp/v2/posts"
      );

      if (res.status == 200) {
        setblogs([...res.data]);
      }
    };
    gtmPageViewEvent(window.location.pathname, window.location.host);
fn();
  }, []);
  return (
    <div className="h-screen flex flex-col block hide-scroll-bar scrollbar-hide worksans w-screen overflow-x-clip">
      <Header />
      <Helmet>
        <link
          rel="canonical"
          href={"https://www.bodhi.app"+location?.pathname}
        />
      </Helmet>
      <div className="flex-1 overflow-y-auto flex flex-col">
        <main className="grow hide-scroll-bar scrollbar-hide">
          <div className=" w-full relative bg-[#FFEEEF] overflow-hidden">
            <img className="h-32 md:h-auto w-full object-fill" src={banner} />
            <div className="absolute top-0 w-full h-full flex justify-center items-center">
              <h1 className="text-center text-2xl md:text-4xl font-semibold text-white">
                Gemini Woman
              </h1>
            </div>
          </div>
          <div className="container mx-auto flex flex-col">
            <div className="flex flex-wrap my-2 ml-2">
              <a
                href="https://www.bodhi.app/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Bodhi</h4>
              </a>
              
              <h4 className="mx-2 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/zodiac-signs/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Zodiac Signs</h4>
              </a>
              <h4 className="mx-2 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/zodiac-signs/gemini"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Gemini</h4>
              </a>
              
              <h4 className="mx-2 text-sm">/</h4>
              <h4 className="text-sm">Gemini Woman</h4>
            </div>
            <div className="flex mt-10 items-center">
              <div>
                <img className="h-24 md:h-48" src={logo} />
              </div>
              <div className="flex-1 flex flex-col md:flex-row">
                <div className=" flex-1 flex flex-col">

                <h1 className="font-semibold text-xl md:text-4xl ml-4">Gemini Woman</h1>
                <h2 className="text-sm md:text-base ml-4">May 21 - Jun 21</h2>
                </div>
                <div className="">
                <ZodiacDD name={"Change Sunsign"} />
              </div>
              </div>
              
            </div>
            <div className="grid grid-cols-3 md:grid-cols-4 gap-2 md:gap-8 mt-10 text-[0.8rem] md:text-base mx-1">
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2 py-1" onClick={() => {
                    navigate("/zodiac-signs/gemini/gemini-woman");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Gemini Woman</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>

              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/gemini/gemini-health");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Gemini Health</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/gemini/gemini-career");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Gemini Career</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/gemini/gemini-love");
                  }}>
                  <h5 className="font-semibold text-black">Gemini Love</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/gemini/gemini-nature");
                  }}>
                  <h5 className="font-semibold text-black">Gemini Nature</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/gemini/gemini-man");
                  }}>
                  <h5 className="font-semibold text-black">Gemini Man</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/gemini/gemini-relationship");
                  }}>
                  <h5 className="font-semibold text-black">Gemini Relationship</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/gemini/gemini-traits");
                  }}>
                  <h5 className="font-semibold text-black">Gemini Traits</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/gemini/gemini-facts");
                  }}>
                  <h5 className="font-semibold text-black">Gemini Facts</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
            </div>
            <span className="my-4 mx-1 md:mx-0">


            <p className="playfair text-black text-xl md:text-3xl">Gemini Women</p> <br/>
<p className="font-bold text-orange-500 inline-block mr-2">Traits:</p>dynamic, adjustable, versatile, enthusiastic, soft-spoken, humorous, intellectual, lack of consistency, lack of decision-making ability<br/><br/>
Compatibility Signs for Gemini Women: Aries, Libra, Scorpio, Sagittarius, and Aquarius<br/><br/>
You are a perfect blend of being sarcastic and kind. You are a person who is very kind and generous. You are an extroverted person in a hot, nurturing way. You are a person who is very charismatic and energetic.
 <br/><br/>
You are fully competent, and you are very cheerful. You are very loving. You are perfect, and you are beautiful. You are the correct type of friend to have. You are very energetic and fun to be around. You are very conscientious and caring. You are a good listener and enjoy the conversation.<br/><br/>
You are a sincere person and are not afraid to speak up, even when you don't agree with someone. You have a flair for decorating, and you find the perfect way to decorate a room. You are a very creative person, too. You can keep many things in your head and never know the result of what you have done.
 <br/><br/>
 <p className="font-bold text-orange-500 inline-block mr-2">
 Gemini women:
              </p><br/><br/>
You are loving, caring, a little impulsive, and possess a dual personality. Your life is full of both. The good and bad luck you sometimes the experience can be related to many things you can control. You can manage your ability to give and take, be generous, and love. You can possess the ability to let go and move on.<br/><br/>
You constantly struggle in life to balance between your desire for spontaneity and the occurrence of a lucky chance.
 <br/><br/>
Due to dual personality, you will love something one day and hate the same thing on other days. At times, you are your own worst critic; you are your own best critic at different times.<br/><br/>
We must understand that you are not a static character. You are not the same person at all times. You can change within a fraction of a second. You are not the same person from one moment to the next. 
 <br/><br/>
As a person, you're complex between your interests and passions because you change your thoughts quickly. Your heart, mind, and soul are wanderers. You are very romantic in terms of expressing your feelings and deep emotions. The people around you will be pleased in no time. There are no areas that ignite your interest forever. You are the one who is always ready to help others in challenging situations. 
 <br/><br/>
 
 
 <p className="playfair text-black text-xl md:text-3xl">Let’s take a look at Gemini women's traits
 </p>
 <br></br>
 <p className="playfair text-black text-xl md:text-3xl">Dynamic and Adjustable</p><br/>
You are adaptable and accept the circumstances that you are facing. Thus, you will be a perfect wife. Considering your qualities, you're broad-minded, aware, and believe in appreciating every moment of life. You can adjust and adapt to the changing scenario and life situations as you are a change receptor. You possess an easygoing demeanor that can flip in a short time. You are positively flexible and don't want to miss anything in life. Your vigorous mind and soul are constantly aching to learn. This learning ability makes you different compared to other zodiac sign women. To be in that sad mood during a tough time, you will happily be involved in repositioning your thoughts and getting new information.
 <br/><br/>
 <p className="playfair text-black text-xl md:text-3xl">Versatile</p><br/>
This versatility shows many traits about you. Capable of handling and managing several tasks at a time. You feel very enthusiastic when you have to do a mixture of things. You are not afraid of learning new things, and in fact, you're very curious to try new things and are an expert in them. You will never commit yourself to a mundane task. You are a socialist who is passionate about your ideas and would like to have a universal life. In this way, you would love to explore other cultures and live in harmony with people. You are a socialist who wants to have a universal life.
 <br/><br/>
 <p className="playfair text-black text-xl md:text-3xl">Open-minded</p><br/>
You are very open-minded and have a warm heart and mind. You are similar to a butterfly who is always welcoming and friendly to people. You are very good at catching people's attention. You can speak to unknown people/strangers flawlessly and at ease. You will always be talking wherever you go to discuss the ideas and gather new ideas and information. Mirroring the best and worst parts of others and showing them things they never needed to see is important because it will make others look at them differently if you use it well.
 <br/><br/>
<p className="playfair text-black text-xl md:text-3xl">Enthusiastic</p><br/>
You are full of enthusiasm, adventurous, and full of life. You are very positive but also very inward-looking. You are a team player, and you take your role very seriously. You like to have fun, and you are always looking for new experiences. You also want to work hard, be punctual, and equally severe in your daily routines and routines within your team.
 <br/><br/>
<p className="playfair text-black text-xl md:text-3xl">Soft-spoken</p><br/>
You are blessed with excellent communication skills and the queen of conversations as Mercury rules you. You are very soft-spoken and will do well in the deals and marketing industriousness. You really like talking and interacting with others. You are smart that You swiftly take the lead in the conversation. It's your genre, and people love you when you speak the language of others and make them feel comfortable talking with you.
 <br/><br/>
<p className="playfair text-black text-xl md:text-3xl">Living life to the fullest</p><br/>
You always love to go on an adventurous trips. You have a lot of guts and stamina that very few people can keep up. 
You got this life's mantra: Life is too short, and time is always flying for you as you want to share the most pleasing thing in the world. Your timing is running, and that constantly ticking sound is hammering in your head because it's time to fly like a butterfly who always moves from one place to another to enjoy life with lots of fun and new experiences with new things. You are witty and quick with monologues and one-liners that will entertain others.
 <br/><br/>
<p className="playfair text-black text-xl md:text-3xl">Intellectual</p><br/>
You are highly clever and possess the ability to discuss any subject under the sun. You are also an avid reader. So you could understand how the different sections of this course can help you manage your time better and learn new things. Your formidable intelligence and intellect will always be in a state of action, rushing from one subject to the next. Your mind is like a runaway train. You are always thinking of something, and you never stop. You are always racing to get to your next thought. This makes it very difficult for you to concentrate.
 <br/><br/>
No matter how smart you are, you will always be in a state of action. You will be able to think in a way that no one else will be able to. You will be able to believe in a way that no one else will be able to. Before anybody can grasp what is in your mind, you will have moved on to something else. You can't help but be curious and have a great curiosity. 
 <br/><br/>
You might not know everything, but you will know things that you never knew before. You have an inner desire to know more and question everything you do. You also can learn things that you never knew before. You are a great one and a great thinker. You are also brilliant at finding the truth and understanding it. You will think that you are a genius. You will always dream about the latest and greatest technology.
 <br/><br/>
<p className="playfair text-black text-xl md:text-3xl">Lack of consistency</p><br/>
Your mind will not settle down in one place for a more extended period. Your versatility and sharpness will not let you stay inconsistent with anything. Many unfinished projects are in a queue, and you will always jump to a new topic or project. This does not happen because of laziness but because you get easily distracted.<br/><br/>
Lack of decision-making ability<br/><br/>
You will quickly change your mentality for the good things you want in life. You are unstable, and responsibility will not come to you quickly. You are a visionary who is happy to outsource work that requires too much attention. You are not a fair planner as you see all things from too many angles and keep evaluating things without arriving at a decision. Repeatedly change your mind, which will not let you make a final decision. You are capricious and changeable as you supervise getting bored very quickly. Hence, you may lose essential opportunities in life.
 
How do you know about Gemini women in love and relationships?<br/><br/><br/>
Gemini in love and relationships is attractive, passionate, feminine, and adorable. You are regularly in motion, busy, noisy, chatty, and explode from the trimmest excuse. You cannot be in love and relationships once you start getting bored. You cannot accept monotony and mediocrity in a relationship. You will tempt your partner with your quick wit, cleverness, versatile interests, and friendliness. You will look for your soulmate vigorously but are never despairing and get overpowered by your emotions.
 <br/><br/>
 
 <p className="playfair text-black text-xl md:text-3xl">How To Attract Gemini Women?</p><br/>
 
In the case of a Gemini woman, she is quick to change her mind, for she is in a stage where she can be very decisive. She is swift to reject your suggestions and commands, and she will not wait for you to finish your work before she starts her plans. She is also quick to complain about anything that happens to her, and she never stops complaining about anything and will not listen to any reason.
 <br/><br/>
It is not easy to attract a Gemini woman as she demands based on her standards and is quick to change her mind. She is not prone to commit and is not inclined to settle soon.
 <br/><br/>
 <p className="playfair text-black text-xl md:text-3xl">Here are a few suggestions that will help catch and impress Gemini women.
 </p>
 <br/>
Understand her dual nature: To be with a Gemini woman means to know two or more people. You need to understand her dual qualities as she will always be in a dilemma. However, both the personalities are equally impressive and fascinating. Whenever she decides anything, you should wait for some time and then act on it because there are chances when she may revise her thoughts.
 <br/><br/>
Be a good listener: You need to be a good listener because she talks a lot. You have to be attentive and sincere while listening to her. You need to listen and be alert to the person you speak with. It may be difficult as it is not easy to be quiet and listen properly. You should not be just listening to not being interrupted but should bring your attention to what is said. <br/><br/>
There are several situations that you need to be aware of. The first is that you should be able to listen without being judgmental. You can make intelligent and relevant comments in between her stories when needed.
 <br/><br/>
Give her lots of space: If you want to impress a Gemini woman, you need to give her a lot of space. You need to trust her fully and not act possessively. It would be best if you did not pressure her into any commitment and not ask too many personal questions. It would help if you were adjustable with her changing mind and goals. She is exceptionally gregarious and free-spirited so never try to own her.
 <br/><br/>
Make her laugh: You need to be a fun match for her. You need to shine your wit and be humorous. You should send entertaining messages at frequent intervals. It would be best if you kept everything pastel and fluffy to engrave and plead to her inner child. Avoid consulting emotional and depressive matters.
 <br/><br/>


            </span>
          </div>
          <div className="container mx-auto">
            <div className="flex justify-between mb-4 mx-2">
              <h1 className="text-xl md:text-3xl text-black playfair">
                Recent Blogs
              </h1>
              <a
                className="text-sm md:text-md text-orange-500 text-center font-semibold"
                href="https://www.bodhi.app/blog/"
              >
                See More
              </a>
            </div>
            <div className="relative">
                <div className="absolute top-0 right-0 w-0 md:w-36 fixed h-full bg-gradient-to-r from-transparent to-white"></div>
              <div className="flex overflow-x-scroll pb-4 mt-2 hide-scroll-bar scrollbar-hide">
                <div className="flex flex-nowrap ml-2">
                  {blogs.slice(0, 10).map((item, i) => {
                    return (
                      <a href={item.link} key={i} target="_blank">
                        <div className="flex flex-col h-80 w-52 justify-start items-start bg-gray-100 mr-10 mb-10 rounded-lg overflow-hidden hover:shadow-lg">
                          <img
                            className="object-cover w-52 h-52 w-auto bg-gray-100"
                            src={
                              item?.yoast_head_json?.og_image
                                ? item?.yoast_head_json?.og_image[0]?.url
                                : ""
                            }
                          />
                          <span className="mt-2 block mx-2 text-center">
                            {item?.yoast_head_json?.title ?? ""}
                          </span>
                        </div>
                      </a>
                    );
                  })}
                  {/* <div className="flex h-80 w-52 justify-center items-center "></div> */}
                </div>
              </div>
            </div>
          <button
            className="justify-center text-white fixed right-2 bottom-2 rounded-t-2xl but px-4 py-2 flex gap-1 shadow-xl"
            onClick={() => {
              navigate("/consultAstro");
            }}
          >
            {user.name !== undefined?`Consult Top Astrologers Now!`:`Login & get ${
              user?.currencyType == "usd" ? "$ 4" : "₹ 91"
            } in your account!`}
          </button>
          </div>
        </main>
        <Footer />
      </div>
    </div>
  );
}

export default Index;
